import En from './lang/en.json';

const numberFormats = {
    'en': {
        currency: {
            style: 'currency', currency: 'TKN', notation: 'standard'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
}
export default defineI18nConfig(() => {
    return {
        legacy: false,
        locale: 'en',
        messages: {
            en: En
        },
        numberFormats,
    } as never
})